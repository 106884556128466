<script setup>

const props = defineProps({
    modelValue: String | Boolean,
    label: {
        type: String,
        default: null
    },
    type: {
        type: String,
        default: 'text'
    }
});
const input = ref(null);
const attrs = useAttrs()

defineEmits(['update:modelValue']);

onMounted(() => {
    if ('autofocus' in attrs) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <label :class="[{'opacity-50' : attrs.disabled}, 'flex-none flex flex-col gap-1']" >
        <div class="flex font-medium text-small gap-0.5" v-if="label">
            <span v-if="'required' in attrs" class="text-negative-tertiary">*</span>
            <div v-html="label"></div>
        </div>
        <div class="relative">
            <span v-if="props.type === 'search'" class="icon-fluent-search-24-filled text-tertiary absolute left-2.5 size-6 top-1/2 -translate-y-1/2 z-[2] pointer-events-none"></span>
            <span class="absolute right-4 z-[2] top-1/2 -translate-y-1/2 pointer-events-none text-small" v-if="attrs.affix" v-html="attrs.affix"></span>
            <component :is="props.type === 'textarea' ? 'textarea' : 'input'"
                ref="input"
                v-bind="$attrs"
                :type="props.type"
                :class="{'!border-negative !ring-negative': attrs.error}"
                class="bg-accent-overlay-6 border-accent-overlay-18 focus:border-primary focus:ring-primary rounded-md shadow-sm w-full resize-none transition-colors disabled:opacity-50"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
            ></component>
        </div>
        <FormError v-if="attrs.error" :message="attrs.error" />
    </label>
</template>
<style>

</style>
